const LOCAL_API_BASE_URL = "http://localhost:4007/api";
const LIVE_API_BASE_URL = "https://vendorapi.eventstan.com/api"
const UAT_API_BASE_URL = "https://uatvendorapi.eventstan.com/api"

//export const API_BASE_URL = UAT_API_BASE_URL;
export const API_BASE_URL = (window.location.host === 'vendor.eventstan.com') ? LIVE_API_BASE_URL : UAT_API_BASE_URL;
// export const API_BASE_URL = UAT_API_BASE_URL; //jerry only on local

console.log(`API URL is ${API_BASE_URL}`);
// Note Master Password is ev$S7qvxI2rT
